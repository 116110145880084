<template class="space">
    <div class="section">
        <span class="header-space section-header left">{{ this.header }}</span>
        <DataTable v-if="this.policyOverview" :value="this.policyOverview" @row-dblclick="$emit('doubleclick', $event)"
            paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" removableSort stripedRows>
            <template #header>
                <div style="text-align: left">
                    <Button icon="pi pi-external-link" label="Clear Filter" @click="$emit('clearFilter')" />
                    <span style="margin: 10px">Doubleclick to filter by specific policy.</span>
                </div>
            </template>
            <Column field="line" header="Line" sortable />
            <Column field="policyDescription" header="Policy Description" sortable />
            <Column field="mainPolicyNumber" header="Policy #" sortable />
            <Column field="lineStatusCode" header="Status" sortable />
            <Column field="effectiveDate" sortField="effectiveDateTime" header="Eff. Date" sortable>
                <template #body="policy">
                    <span>{{ this.convertToDate(policy.data.effectiveDate) }}</span>
                </template>
            </Column>
            <Column field="expirationDate" sortField="expirationDateTime" header="Exp. Date" sortable>
                <template #body="policy">
                    <span>{{ this.convertToDate(policy.data.expirationDate) }}</span>
                </template>
            </Column>
            <Column field="ppe" header="PPE" sortable />
            <Column field="ico" header="ICO" sortable />
            <Column field="limit" header="Limit" sortable />
            <Column field="deductible" header="Deductible" sortable/>
            <Column field="estimatedPremium" header="Premium" sortable>
                <template #body="policy">
                    <span>{{ this.formatCurrency(policy.data.estimatedPremium) }}</span>
                </template>
            </Column>   
        </DataTable>
    </div>
</template>

<script>
export default {
    name: 'PolicyOverview',
    emits: ['doubleclick', 'clearFilter'],
    props: {
        policyOverview: {
            type: Object,
            required: true
        },
        header: String,
        doubleclick: Function,
        clearFilter: Function
    },
    methods: {
        convertToDate(string) {
            var parts = string.toString().split('/');
            var date = new Date(parts[2], parts[0] - 1, parts[1]);
            return this.getFormattedDate(date);
        },
        getFormattedDate(date) {
            var year = date.getFullYear();
            var month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;
            var day = date.getDate().toString();
            day = day.length > 1 ? day : '0' + day;

            return month + '/' + day + '/' + year;
        },
        formatCurrency(value) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
        },
    }
}
</script>